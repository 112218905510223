
form{
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    label{
        color: #ff8800;
        font-size: 22px;
    }

    input{
        width: 200px
    }

    button{
        margin-top: 10px;
        width: 100px;
        height: 60px;
        background-color: black;
        color: #ff8800;
        font-family: "Bebas Neue", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 30px;
        padding: 5px
    }

    textArea{
        width: 500px;
        height: 800px
    }
}